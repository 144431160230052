import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Packages = ({ packageData }) => (
  <section className="packages">
    <div className="wrapper">
      <h2>Automation Packages</h2>
      <div className="columns columns--packages">
        {packageData.map((service, index) =>
          <div key={index} className="column package">
            {service.node.data.name && service.node.data.full_details.html ? (
              <h3 className="package__title"><Link to={service.node.uid}>{service.node.data.name.text}</Link></h3>
            ) : (
              (service.node.data.name ? (
                <h3 className="package__title">{service.node.data.name.text}</h3>
              ) : (
                ''
              ))
            )}
            {service.node.data.description &&
              <p className="package__description">{service.node.data.description.text}</p>
            }
            {service.node.data.price &&
              <div className="package__price">${service.node.data.price}</div>
            }
            <div className="package__select">
              {service.node.data.name.text &&
                <Link to="/get-started" state={{ serviceSelected: service.node.data.name.text }} className="cta cta--primary">Select Package</Link>
              }
              {service.node.data.full_details.html &&
                <Link to={service.node.uid} className="package__learn">learn more</Link>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  </section>
);

Packages.propTypes = {
  packageData: PropTypes.array.isRequired
};

export default Packages;
