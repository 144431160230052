import React from 'react';
import Packages from './packages';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ImgServicesHeroBg from './services__hero__bg.svg';
import ImgServicesHeroGraphic from './services__hero__graphic.svg';

const Services = ({ packageData }) => (
  <main>
    <div className="hero hero--services">
      <div className="wrapper">
        <h1>Our Social Media Automation Services</h1>
        <p>Our services are fine-tuned to help health coaches grow their business by delivering what their audience is looking for in lifestyle advice and establishing a loyal following around their brand.</p>
      </div>
      <div className="hero--services__img">
        <img src={ImgServicesHeroGraphic} alt="" width="446" height="318" className="hero--services__img__graphic" />
        <img src={ImgServicesHeroBg} alt="" width="929" height="436" className="hero--services__img__bg" />
      </div>
    </div>
    <Packages packageData={packageData} />
    <section className="section-cta section-cta--home">
      <div className="wrapper">
        <h2>Ready to get started?</h2>
        <Link to="/get-started" className="cta cta--primary">Start Now!</Link>
      </div>
    </section>
  </main>
);

Services.propTypes = {
  packageData: PropTypes.array.isRequired
};

export default Services;
